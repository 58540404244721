.EditProfilePage_wrapper{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


}
.EditProfilePage_wrap{
    width:100%;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
height: max-content;
    overflow: hidden;

    align-self: center;

    box-shadow: 5px 5px 10px rgba(0,0,0,.7);
    border-radius: 15px;
    padding: 15px;
}
.EditProfilePage_form{
    width:100%;
    height:100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 15px;
    position: relative;
    overflow: auto;
    max-height: 70vh;


}
.EditProfilePage_photo_social{
    width:100%;
    height:max-content;
    display: grid;
    grid-template-columns: 135px 1fr;
    grid-gap: 10px;

}
.ant-form-item{
    margin: initial;
    width: 100%;
}

.Edit_build .ant-row{

}
.Edit_social{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    gap: 10px;


}
.Edit_social .ant-form-item .ant-row {
display: grid;
    grid-template-columns: 140px 1fr;
}
.ant-form-horizontal .ant-form-item-label{
    display: flex;
    align-items: baseline;
    padding-top: 5px;
}
.Edit_address,
.Edit_user{
    width:100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;

}
.ant-form-item-control-input{

    width: 100%
}
.DatePicker_item .ant-form-item-control-input .ant-picker{
    width: max-content;
    display: flex;
    align-items: flex-start;
}
.ant-divider-horizontal.ant-divider-with-text{
    margin: 0;
}
.Edit_build{
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns:140px 140px;
    grid-template-rows: max-content;
    grid-gap: 15px;
}
.Edit_build .ant-form-item,
.Edit_build .ant-row{
    width: max-content;
}
.Edit_build .ant-col.ant-form-item-control{
    width: 70px;
}
.ant-form-item .ant-form-item-label >label{
    font-size: 16px;
    font-weight: 600;
}
.Edit_control_item{
    width: 100%;
    height:max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;

    bottom: 0;

}
.Edit_control_info{
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 15px;
}
.Edit_control_info svg{
    width: 30px;
    height: 30px;
    padding-top: 5px;

}
.btn_submit_item_wrap .ant-form-item-control-input-content{
    width:max-content;
    grid-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Edit_control_info > span{
    cursor: pointer;
}
.AddAdsPage_block_EAdvertState,
.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item{
    text-align: left;
}
.Edit_photo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    max-height:110px;
}
.Edit_photo img{
    display: flex;
    height: auto;
 max-height: fit-content;

}
.avatar-uploader_img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.EditProfilePage_wrap .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    content: none;
}
.Edit_photo_roles{
    display: flex;
    flex-direction: column;
}
.userIbirthdate{
    padding-top: 5px;
    width: 100%;
    height:     max-content;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    grid-gap: 10px;
}
.street_style_input .ant-row,
.building_input  .ant-row,
.Edit_user .ant-row{
    display: grid;
    grid-template-columns: 135px 1fr;
}
.building_input .ant-row{
    grid-template-columns: 135px 80px;
}
.AddAdsPage_block_cats .ant-select-selection-placeholder{
text-align: left;
}
.AddAdsPage_block_description .ant-input-textarea-show-count .ant-input-data-count {
bottom: 0;
    right: 8px;
    font-size: .8em;
    opacity: .8;
}
.AddAdsPage_block_ves{
  display: flex;
    align-items: center;
    justify-content: flex-start;
}
