.CardInitiative_wrapper{
    width:100%;
    height: max-content;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;



}
.CardInitiative_wrapper > div{
    background: white;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
    background-clip: padding-box;
    border: 0;
    border-radius: 30px;
}
.CardInitiative_modal.ant-modal{
    max-width: 100vw;
    width: max-content;
    min-width: min-content;
}
.CardInitiative_modal .CardInitiative_map{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    min-width: 500px;
}
.CardInitiative_item{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    min-width: 500px;
}
.CardInitiative_item > div{
    width:100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;


}
.CardInitiative_name > span:first-child{
    font-size: clamp(14px, 5vw, 16px);
    font-weight: 600;
    color: #B0B0B0;
}
.CardInitiative_name > span:last-child{
    font-size: clamp(14px, 5vw, 16px);
    color: #1E1E1E;
    max-height: 150px;
    overflow: auto;
    text-align: justify;
    word-break: break-all;
}

.CardInitiative_name > span:last-child::-webkit-scrollbar {
    width: 5px;
}
.CardInitiative_name > span:last-child::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
}

.CardInitiative_name > span:last-child::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, #B0B0B0, #5b5a5a);
}






.CardInitiative_name > span:last-child::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
}
.CardInitiative_title{
    font-size: clamp(14px, 5vw, 32px);
    font-weight: 700;
    color: #1F4DC5;
/*    margin-top: -20px;*/
}
.CardInitiative_max{
    width: 100%;
}
.CardInitiative_modal.ant-modal .ant-modal-content{
    background: inherit;
    box-shadow: none;
    background-clip: inherit;
    border: none ;
    border-radius: 0;
    padding: 0;
}

.ant-modal .ant-modal-close{
    z-index: 100;
}
.CardInitiative_docs{
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, 35px);
    grid-auto-rows: max-content;
    overflow: auto;
    max-height: 80px;
    grid-gap: 10px;
}

.CardInitiative_docs svg{
    width: 100%;

}
.CardInitiative_img{
    width: 100%;
    height: 100%;
    min-height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.category_filter_wrap{
    width: 100%;
grid-gap: 10px;

}
.category_filter_wrap .ant-row{
    width: 100%;

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 10px;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;


}
.category_filter_wrap  .ant-form-item-control
{
    width: 100%;
    padding: 0 10px;
}
.category_filter_wrap.ant-form-item .ant-form-item-label >label{
    color: white;
}
