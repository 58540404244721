
.ScrollBlock_wrap {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;

    background-image: url('../../assets/image/topBack.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

}

.carousel-item {
    padding: 10px;
}
.ScrollBlock_title{
    font-size: clamp(16px, 1.8vw, 32px);
    line-height: 40px;
    /* identical to box height */
    padding: 15px 10px 0 40px;
position: absolute;
    top: 0;
    color: #FFFFFF;
}

.ScrollBlock_wrap .slick-slider {
    position: relative;
    height: 100%;
    max-width: 100%;
    display: flex;
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-bottom: 10px;

}

.slick-list {
    position: relative;
    overflow: hidden;
    margin: 0;


    height: 100%;

    padding-top: 53px!important;

}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    width: max-content;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: black; /* edit for arrow colour */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

.slick-prev:before {
    content: url('../../assets/icons/left_arrow.svg');
    width: 200px;
    height: 200px;
}
.slick-next {
    right: -25px;
}
.slick-next:before {
    content: url('../../assets/icons/right_arrow.svg');
    width: 200px;
    height: 200px;
}


.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-arrow{
    padding-top: 25px;
}
.carousel-item_card{
color: white;
    height: 100%;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 100px;
    grid-gap: 10px;
    border: 1px solid #a8a8a8;
    background: linear-gradient(89.92deg, #878fa5 0.06%, #586689 101.02%);
    border-radius: 10px;
    align-items: center;
    max-height: 100px;
    position: relative;
    width: 380px;
    cursor: pointer;
}

.carousel-img-back{
    position: absolute;
    top:-40px;
    right: 50%;
    left: 50%;
    height: 150%;

}
.carousel-item_text{
    text-align: left;
    font-weight: 700;
    font-size: clamp(14px, 5vw, 18px);
    line-height: 24px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #FFFFFF;
    z-index: 1;
}
.carousel-item_value{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.carousel-item_value span:first-child{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;

    line-height: 32px;
}
.carousel-item_value span:last-child{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}
