.leaflet-popup-content {
    color: #000 !important; /* Установить цвет текста */
    font-size: 16px !important; /* Установить размер шрифта */
}

.leaflet-pane.leaflet-map-pane{
    z-index: 1;
}
.leaflet-control-attribution.leaflet-control{
    display: none;
}
