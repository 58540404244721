.ChangePasBlock_wrapper{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.ChangePasBlock_wrap{
    width: max-content;
    min-width: 600px;
    height: max-content;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 50px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
   /* grid-gap: 40px;*/
    background: white;
}
.ChangePasBlock_wrap > div{
    width: 100%;
    color: #9A9A9A;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
    height: max-content;
    display: flex;
    flex-direction: column;
}
.ChangePasBlock_wrap > div > span{
    padding-bottom: 20px;
}
.ChangePasBlock_title_text{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    color: #1F4DC5;
    text-align: center;
    justify-content: flex-start;
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
}
.ChangePasBlock_wrap form{
    width: 100%;
}
.ChangePasBlock_wrap .ant-form-vertical .ant-form-item-label >label{
    color: #9A9A9A;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
.ChangePasBlock_wrap .ant-form-item{
    margin-bottom: 30px;
}
.ChangePasBlock_wrap .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    content: none;
}

.ChangePasBlock_wrap input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #333;
}
.ChangePasBlock_wrap .ant-input-lg{
    line-height: 55px;
}
.ChangePasBlock_wrap .btn_submit_item{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
    width: 240px;
    height: 70px;
    border: 3px solid #1F4DC5;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1F4DC5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.ChangePasBlock_explanation{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #9A9A9A;
    padding-bottom: 26px;
}
.ChangePasBlock_title_text_wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    grid-gap: 15px;
}
.ChangePasBlock_title_arrow{
    cursor: pointer;
}
.ChangePasBlock_wrap .ant-message.ant-message-top{
    z-index: 100;
}
.ChangePasBlock_wrap .ant-message-custom-content.ant-message-success{
display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ChangePasBlock .ant-form-item-control-input-content{
    display: flex;
    align-items: center;
    justify-content: center;

}
.ChangePasBlock_success{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    padding: 0 10px;
}
