.TableInitiative_wrap {
    width: 100%;
    height: 100%;
    max-width: 80vw;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    max-height: max-content;
min-width: 100%;
    overflow-x: auto;
    color: #00005B;
}
.NO_DATA,
.TableInitiative_table {
    width: 100%;
    height: 100%;
background: #ffffff;


}


.TableInitiative_table.ant-table-wrapper td.ant-table-column-sort,
.TableInitiative_table.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.TableInitiative_table.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: inherit;
    /* cursor: pointer;*/
}
.TableInitiative_table.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container{
    width: 100%;
}
.TableInitiative_table.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    color: #6B6C71;


}
.btn_edit_line_wrap{

    align-items: center;
    justify-content: center;

}
.btn_edit_line svg {
    margin: auto;
    width: 20px;
    height: 20px;


}
.TableInitiative_table.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td:first-child{
    /*    display: flex;
        align-items: center;*/
    text-align: left;
}
.TableInitiative_table.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td:first-child:hover{
    color: #cb3f3f;
}
.TableInitiative_wrap .ant-table-body{
    padding-bottom: 20px;

}
.metList_PP_tableS .TableInitiative_wrap .ant-table-body{
    padding-bottom: 30vh;
}
.TableInitiative_wrap .ant-table-body tr{
    color: #00005B;
    cursor: pointer;
}
.TableInitiative_wrap .ant-table-body tr:hover{
    background: rgba(107, 108, 113, 0.05);
}
.modal_item_search .ant-form-item{
    margin-bottom: 0
}
.button_table_style{
    border: none;
    background: none;
    cursor: pointer;
}
.TableInitiative_wrap .ant-table-wrapper .ant-table-filter-column{
    width: max-content;
}

.metList_PP_table .ant-table-tbody .ant-table-row .ant-table-cell:last-child{
    text-align: center;
}
.metList_PP_table .ant-table-tbody .ant-table-row .ant-table-cell:last-child span{
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.85);
    padding: 5px 10px;
    display: block;
    width: 100%;

}
.TableInitiative_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
    border-left: none;
    border-right: none;
}

.TableInitiative_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
    border-inline-end: white;
    border: none;
    background: white;

}
.BodyBlock_wrapper .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{

    border-bottom: 1px solid #EEEEEE;
}
.TableInitiative_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{
    border: none;

}
.metList_PP_tableS,
.metList_PP_table{
    overflow: hidden;
}
.metList_Bi_logo{
    width:100%;
    height:max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    padding: 15px 0;
    background: white;
    border-radius: 15px;
    cursor: pointer;

}
.metList_Bi_logo_text{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
    width: max-content;
    height: 100%;
    color: #1C3A64;
    font-family: 'Poppins' ,sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: left;
}

.TableInitiative_table .ant-table-container{
    padding: 0 10px;
  /*  max-width: 1600px;*/

}
.TableBlock_tab .ant-tabs-top >.ant-tabs-nav{
   margin: 0;
}
.TableBlock{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
}
.TableBlock .ant-tabs{
    width: 100%;
    max-width: 1600px;

}
.TableBlock .ant-tabs .ant-tabs-tabpane{
    width: 100%;
    max-width: 1600px;
}
.TableInitiative_wrap{}
