.Dashboard_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 180px 1fr;
    grid-gap: 5px;
    max-height: 100%;
    position: fixed;
    padding: 80px 0 50px 0;
}


.Dashboard_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 400px;
    grid-template-rows: 100%;
    grid-gap: 20px;
    padding: 0 3vw;

    overflow: auto;
    max-height: 100vh;

}

.Dashboard_banner {
    width: 100%;
    display: flex;
    height: 110px;
    max-height: 180px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
}

.Dashboard_banner span {
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 40px);
    letter-spacing: 0.005em;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.Dashboard_banner img {
    display: flex;
    align-self: flex-end;
    padding-right: 3vw;
}

.Dashboard_banner_bottom_text {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(16px, 1vw, 20px);
    line-height: 135.02%;
    color: #A9A9A9;
}

.Dashboard_left {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content 1fr;
    grid-gap: 10px;
}

.Dashboard_cards_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    grid-gap: 10px;

    /*overflow: hidden;*/


}

.Dashboard_cards_btn_wrap {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;


}
.Dashboard_cards_btn {
    width:max-content;
    height:max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-radius: 10px;
    color: #1F4DC5;
    cursor: pointer;
}
.Dashboard_cards_btn.active,

.Dashboard_cards_btn:hover{

    color: #ffffff;
    background: #1F4DC5;
}
.Dashboard_cards_cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 24%));
    /*grid-template-rows: repeat(auto-fill, minmax(220px,23vh));*/
   grid-auto-rows: 20vh;
    grid-gap: 10px 20px;
    padding-bottom: 10px;
    min-height: min-content;
    max-height: 100%;
    /*overflow: scroll;*/


}
.Dashboard_card_title{
    font-style: normal;
    font-weight: 600;
    font-size: clamp(18px, 1vw, 40px);
    text-align: left;
    line-height: 29px;
    /* identical to box height */
    color: #000000;

}
.Dashboard_card {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    grid-gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
    cursor: pointer;

}
.Dashboard_card:hover{
    background: rgba(202, 215, 250, 0.25);
}
.Dashboard_card_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

}
.Dashboard_card_item > span{
    font-style: normal;
    font-weight: 700;
    font-size: clamp(64px, 4vw, 6em);
    line-height: 77px;

    color: #EB4B18;
}
.Dashboard_card_item > img{
    width: auto;
    height: 15vh;
    margin: 0 -45px -50px 0;
}
.Dashboard_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 30px;
}

.Dashboard_right > div {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

}

.Dashboard_nav_top {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
}

.Dashboard_nav_title {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content;
    grid-gap: 10px;
    padding: 2vh 40px;
    background: #EFF6FF;
}

.Dashboard_nav_title > span {
    display: flex;
    align-items: center;
    width: 100%;

}

.Dashboard_nav_title span:first-child {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: left;
    color: #014B6B;
}

.Dashboard_nav_title span:last-child {
    font-weight: 700;
    font-size: clamp(18px, 2vw, 32px);
    line-height: 39px;
    grid-row: 2/span 1;
    grid-column: 1/ span 2;
    color: #014B6B;
    text-align: left;
}

.Dashboard_nav_title span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #999BA4;
    justify-content: flex-end;
}

.Dashboard_nav_btn_wrap {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    padding: 3vh 20px;
}

.Dashboard_nav_bot {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 30px;
    grid-gap: 5px;
}

.Dashboard_nav_btn {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
cursor: pointer;
    padding: 20px 10px 20px 30px;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    text-decoration: none;
    color: black;

}
.Dashboard_nav_btn.link_item {
    text-decoration: none;
    color: inherit;
}
.Dashboard_nav_btn.disabled{
    cursor:inherit;
}
.Dashboard_nav_bot > span {
    font-style: normal;

}

.Dashboard_nav_bot > span:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.Dashboard_nav_bot > span:nth-child(2) {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ABABAB;
}

.Dashboard_nav_bot > a {
    width: 100%;
    background: #1F4DC5;
    border-radius: 10px;
    padding: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 10px 0;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}
.Dashboard_nav_bot > span:nth-child(3):hover{
    background: rgba(31, 77, 197, 0.88);
}
.Dashboard_nav_bot > span:nth-child(4) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    color: #1F4DC5;
    text-align: right;

}
.Dashboard_cards_btn.map_btn{
display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;


    align-self: flex-start;
}
.Dashboard_nav_reg_wrap{

}
.Dashboard_nav_reg{
max-width: 300px;
}
.Dashboard_nav_reg_item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
