.RulesBlock_wrapper{
    width: 100%;
    height:100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 78px 20px 54px 20px;

    background: #F5F5F5;
}

.RulesBlock_wrap{


    overflow: auto;


    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    text-align: left;
    position: relative;



}

.RulesBlock{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60vw;
    height: max-content;
max-height: min-content;
    padding: 0 40px 40px 40px ;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin: 70px auto 100px;
}
.RulesBlock > h2{
    align-self: center;
    text-align: center;
    padding: 40px 0 5px 0;
   font-style: italic;
}
.RulesBlock > p{
    padding-left: 20px;
    font-size: clamp(16px, 5vw, 18px);
}
.RulesBlock > span{
    text-decoration: underline;
    font-size: clamp(16px, 5vw, 22px);
    padding-left: 20px;
    padding-bottom: 10px;
}
