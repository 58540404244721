.IniMapPage_wrap .Main_wrapper{
    width: 100%;
    height: 100%;

    font-family: Roboto,sans-serif;
    position: relative;

    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr minmax(200px, max-content);


}
.IniMapPage_wrap .CardsPanel_lazy{
    width: 320px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}
.IniMapPage_wrap .Main_wrapper .ant-spin{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
}

.IniMapPage_wrap .Main_control{
    position: relative;
    display: flex;
    width: max-content;
    min-width: 260px;

}



.IniMapPage_wrap .List {
    border: 1px solid #d9dddd;
}

.IniMapPage_wrap .ListItemEven,
.IniMapPage_wrap .ListItemOdd {
    display: flex;
    align-items: center;
    justify-content: center;
}

.IniMapPage_wrap .ListItemEven {
    background-color: #f8f8f0;
}
.IniMapPage_wrap .map_element_items{
    z-index: 100;
    position: absolute;
    display: flex;
   left: 0;
    top: 5px;
}
.IniMapPage_wrap .map_element_items.nav_show_true{
    left: 335px;
}

.Map_wrapper .leaflet-control-attribution.leaflet-control{
    display: none;
}
