.WorkMapPage_wrap{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 100%;
    padding: 80px 0 54px 0;


}
.WorkMapPage_wrap .DistrictNav_block{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;

    position: relative;

}
.WorkMapPage_wrap{
    background: linear-gradient(88.88deg, #15154F 0%, #273BA4 100%);
}
.WorkMapPage_wrap .back_btn_item{
color: white;
}
.WorkMapPage_wrap .back_btn_item svg path{
    fill: white;
}

