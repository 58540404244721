.DistrictNav_wrap {
    display: flex;
    width: 200px;
    height: max-content;
    flex-direction: column;
    grid-gap: 10px;
    position: relative;
    transition: .3s;
    max-width: 300px;
    /*background: #1c3a64;*/
    color: #fff;
    border-radius: 0;
    min-height: 100%;
}

.DistrictNav_btn {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 10;
    cursor: pointer;
}

.DistrictNav_wrap.false .DistrictNav_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 10px;
}

.gerb_bg {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    background-repeat: no-repeat;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
}

.DistrictNav_info_img {
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    max-height: 250px;
    background-position: center;
}

.DistrictNav_item {
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 20px;
    grid-gap: 15px;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    width: calc(100% - 5px);
}

.DistrictNav_item.active,
.DistrictNav_item:hover {
    background: #32578E;
}

.DistrictNav_info_wrap {
    width: 100%;
    min-height: 290px;
    max-height: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px 10px 10px;
    grid-gap: 10px;
    transition: 3s;
}

.DistrictNav_info_title {
    width: 100%;
    height: max-content;
    display: flex;
    font-size: clamp(16px, 5vw, 22px);
    padding: 0 20px;
}

.DistrictNav_info_fio {
    font-weight: 600;
    font-size: clamp(16px, 5vw, 22px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    line-height: clamp(19px, 5vw, 25px);
    text-align: center;
    padding: 0 20px;
}
.DistrictNav_info_hide{
    width:100%
}

.DistrictNav_info_dolzhost {
    font-weight: 400;
    font-size: clamp(12px, 5vw, 18px);
    line-height: 15px;
    color: rgba(255, 255, 255, 0.5);
}

.DistrictNav_item {
    text-align: left;
    line-height: 1.2em;
}

.DistrictNav_wrap.false {
    transform: translateX(calc(-100% + 80px));
    will-change: transform;
    transition: .3s;
    width: 80px;
    padding-top: 10px;
}

.DistrictNav_wrap.false .DistrictNav_item {
    width: calc(100% - 5px);
}

.DistrictNav_info_wrap.true {
    display: none;
    transition: 3s;
}

.DistrictNav_item_wrap.true {
    padding-top: 30px;
}

.DistrictNav_wrap .DistrictNav_info_dolzhost {
    font-size: clamp(12px, 5vw, 15px);
}

.DistrictNav_wrap .DistrictNav_info_title {
    font-size: clamp(14px, 5vw, 17px);
}

.DistrictNav_wrap .DistrictNav_info_fio {
    font-size: clamp(16px, 5vw, 19px);
}

.DistrictNav_wrap .DistrictNav_item {
    font-size: clamp(12px, 5vw, 15px);
}
