.Initiatives_wrapper{
    width:100vw;
    height:100vh;


    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  100vh;
    background: #F5F5F5;
overflow: hidden;



}
