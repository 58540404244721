.NotPage_wrapper{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 100%;
    padding: 80px 0 54px 0;

}


.NotPage_wrap{
    width: 100%;
    height: 100%;
    display: flex;
}
.NotPage_wrap{
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    background-image:  url("../assets/image/404.png");
}
