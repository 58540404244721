.GisSMR_wrapper{
    width:100%;
    height:100%;
    display: grid;
    grid-template-columns: max-content 1fr 220px;
    grid-template-rows: 1fr;
    max-height: 100vh;
    max-width: 100vw;
/*background: #1C3A64;*/
}
.HeaderBlock_wrapper{
    grid-column: 1/ span 3;
    grid-row: 1/ span 1;
}
.WorkMapPage_wrap .DistrictNav_wrap{
    grid-column: 1/ span 1;
    grid-row: 2/ span 1;
}
.GisSMR_wrap{
    grid-column: 2/ span 1;
    grid-row: 2/ span 1;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.CategoryBlock_wrap{
    grid-column: 3/ span 1;
    grid-row: 2/ span 1;
    /*background: #1c3a64;*/

}
.FooterBlock{
    grid-column: 1/ span 3;
    grid-row: 3/ span 1;
    background: #1c3a64;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.72);
    padding: 0 20px;
    font-size: clamp(12px, 5vw, 14px);
    line-height: 12px;

    overflow: hidden;

}
.CardList_wrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding-top: 5px;
}
.CardList_wrap{
    display: flex;
}

.SPIN_item.ant-spin.active{position: absolute;
    z-index: 999;
    background: #edffff4d;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;}
/*ant-spin ant-spin-spinning SPIN_item active css-dev-only-do-not-override-19gw05y
ant-spin SPIN_item  css-dev-only-do-not-override-19gw05y*/
.SPIN_item_spin.ant-spin-dot{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    z-index: 999999;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #6ebeff #6ebeff transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #337AB7 #337AB7;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}
.loader::before {
    width: 32px;
    height: 32px;
    border-color: #6ebeff #6ebeff transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
