.LMap_legend{
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: flex-start;

    color: #146EB0;
    position: absolute;
    bottom: 20px;
    right: 10px;

    z-index: 100;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 10px 10px;

}
.LMap_legend_color_line{
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows:max-content;
    grid-gap: 10px;
    min-height:20px;
    align-items:center;
    text-align:left;
}

.LMap_legend_color_line.camera_items{
    grid-template-columns: max-content 1fr;
    grid-gap: 5px;
}
.LMap_legend_color_icon{
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-template-rows:max-content;
    grid-gap: 10px;
    min-height:20px;
    align-items:center;
    text-align:left;
}
._color_icon{
   grid-gap: 10px;
}

