.TableBlock_wrap{

     width: 100%;
     height: 100%;
     display: grid;
     grid-template-columns: 100%;
     grid-template-rows: max-content 1fr;
     padding: 78px 20px 55px 20px;

 }
.TableBlock_tab{
   position: relative;
}
.TableBlock .ant-tabs-tabpane{
     height: 100%;
     width: 100%;
     padding-bottom: 50px;
}
.TableBlock .ant-empty,
.TableBlock .ant-tabs-content{
     height: 100%;
     width:100%;
     display: flex;
     align-items: center;
     justify-content: center;
}
.TableBlock .ant-tabs-top >.ant-tabs-nav{
     margin: 0;
}
.TableBlock .ant-tabs-nav-list{
     grid-gap: 20px;
}
.TableBlock .ant-tabs-tab-btn{
     font-weight: 600;
     font-size: 20px;
     line-height: 24px;

     color: #00005B;
}
.TableBlock .ant-tabs{
     width: 100%;
     max-width: 1600px;
     margin: 0 auto;
}
.TableBlock_tab .ant-tabs{
     width: 100%;
     max-width: 1600px;

}
.TableInitiative_wrap .TableInitiative_table,
.TableBlock_tab .ant-tabs .ant-tabs-tabpane{
     width: 100%;
     max-width: 1600px;
     height: 100%;
}
.TableInitiative_wrap,
.TableBlock_tab{
     width: 100%;

     display: flex;
     align-items: center;
     justify-content: flex-start;
     margin: 0 auto;
     flex-direction: column;
     height: 100%;
}
.TableInitiative_wrap .ant-spin-nested-loading,
.TableInitiative_wrap .ant-spin-nested-loading .ant-spin-container,
.TableInitiative_wrap .ant-table-wrapper .ant-table,
.TableInitiative_wrap .ant-table-wrapper .ant-table .ant-table-container{
     height: 100%;
}
.TableBlock_years{
display: flex;
     width: max-content;
     align-self: flex-start;
}
