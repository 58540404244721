.IniMapPage_wrap .PopupItem_wrap{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
    grid-gap: 5px;
}

.IniMapPage_wrap .Popup_items.name{
    font-size: 1.5em;
    line-height: 1;
    color: white;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 70%);
    padding-left: 0;

    justify-content: center;
   text-align: left;
    width: 100%;
}
.IniMapPage_wrap .Popup_items:first-child > span{
    flex-wrap: nowrap;
}
.IniMapPage_wrap .Popup_items:not(:first-child){
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;

}
.IniMapPage_wrap .Popup_items > span{
    display: flex;


    align-items: center;


}
.IniMapPage_wrap .leaflet-fade-anim .leaflet-map-pane .leaflet-popup .leaflet-popup-content{
    color: white;
}
.IniMapPage_wrap .leaflet-popup-content-wrapper, .leaflet-popup-tip{
background: #146eb0;
    border: 1px solid #146daf;
    box-shadow: 2px 1px 9px rgb(0 0 0);
}
.IniMapPage_wrap .leaflet-container a.leaflet-popup-close-button{
    color: #dddddd;
}
.IniMapPage_wrap .leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
    color: #ffffff;
}
/*.markercluster-map .leaflet-popup{
    bottom: 3px!important;
    left: -129px!important;
}*/
.IniMapPage_wrap .popup_line_style{
    display: flex;
    align-items: center;
    width: 100%;
    height: max-content;
    grid-gap: 5px;

}
.IniMapPage_wrap .popup_line_style svg{
    width: auto;
    height: 15px;
    display: flex;


}
.IniMapPage_wrap .popup_line_style span{
    display: flex;
    align-items: center;
    height: max-content;
}
.IniMapPage_wrap .Popup_items_double{
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 1fr max-content;
    align-items: center;

}
.IniMapPage_wrap .Popup_items{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
}
.IniMapPage_wrap .Popup_items_likes{
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    padding: 0 10px;
}
.IniMapPage_wrap .Popup_items.like_neg,
.IniMapPage_wrap .Popup_items.like_pos{
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    min-height: 20px;
    grid-gap: 5px;


}
.IniMapPage_wrap .Popup_items.like_neg span,
.IniMapPage_wrap .Popup_items.like_pos span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;



}
.IniMapPage_wrap .Popup_items.like_neg svg{
    transform: rotate(180deg);

}
.IniMapPage_wrap .Popup_items.like_neg svg path{
    fill: #FF0000;
}
.IniMapPage_wrap .Popup_items.like_pos svg path{
    fill: #2FC03E;
}
.IniMapPage_wrap .Popup_items_cat{
    width:100%
}
.IniMapPage_wrap .Popup_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;
}
.IniMapPage_wrap .Popup_item > span{
min-width: 70px;
    width: max-content;
    display: flex;
}
