.FooterBlock_wrap{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(88.88deg, #15154F 0%, #273BA4 100%);
    color: #fff;
    padding: 15px 120px;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}
