.CategoryBlock_wrap{
    padding: 0 5px;
    display: flex;
    width: 100%;
    height:100%;
    overflow: hidden;
}
.RadioCategory_form{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 5px;
}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.RadioCategory_form  .list_for_category .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0;
    display: flex;
    align-items: baseline;
    width: 100%;
}

.RadioCategory_form .list_for_category .ant-checkbox {
    align-self: center;
}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper > span:last-child {
    padding-top: 5px;
    text-align: left;
    line-height: normal;
    color: white;
    padding-inline-end: 0;
    width: 100%;

}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper > span:last-child > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CategoryBlock_wrap .ant-collapse {
    border: none;
    grid-gap: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    padding-bottom: 0;
    border-radius: 5px;

}

.CategoryBlock_wrap .ant-collapse .ant-collapse-content,
.CategoryBlock_wrap .ant-collapse-item {
    border: none;
}

.CategoryBlock_wrap .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    flex-direction: row-reverse;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #316397;
    border-radius: 5px;
    /*border-radius: 0;*/

}
.CategoryBlock_wrap .ant-form{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content max-content max-content max-content max-content 1fr;
    overflow: hidden;
    width: 100%;
    height: 100%;
/*    max-height: calc(100% - 100px);*/
}
.CategoryBlock_wrap .ant-form,
.CategoryBlock_wrap .ant-checkbox-group {
    width: 100%;

}

.CategoryBlock_wrap .ant-collapse .ant-collapse-content {
    background: inherit;
    padding: 10px 0;


}

.CategoryBlock_wrap .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
}

.CategoryBlock_wrap .ant-collapse-header {
    font-size: 16px;
    text-align: left;


}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > span:last-child {
    color: #a6a2a2;
}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper.ant-checkbox-wrapper-checked > span:last-child {
    color: #00EED9;
}

.RadioCategory_form .list_for_category .ant-checkbox-checked .ant-checkbox-inner {
    background-color: inherit;
    border-color: #00EED9;
}
.RadioCategory_form .list_for_category .ant-checkbox-checked:after,
.RadioCategory_form .list_for_category .ant-checkbox .ant-checkbox-inner:after {
    border-color: #00EED9;
}

.RadioCategory_form  .list_for_category .ant-checkbox .ant-checkbox-inner {
    background: inherit;
}

.RadioCategory_form .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: inherit;
    border-color: #00EED9;
}
.RadioCategory_form .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after,
.RadioCategory_form .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-ixblex).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #00EED9;
    background-color:inherit;
}
.RadioCategory_dep.ant-form-item ,
.RadioCategory_status.ant-form-item {

    /*height: calc(100% - 130px);*/
    margin-bottom: 10px;

}
.CategoryBlock_wrap .ant-form-vertical .ant-form-item-label{
    padding-bottom: 0;


}
.RadioCategory_form .ant-form-item .ant-form-item-label >label{
    color: #ffffff;
}
.category_text_header{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    color: white;
    flex-direction: column;
    padding: 0 0 5px 0;
}
.category_text_header_name{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(16px, 5vw, 22px);
    font-weight: bold;
}
.category_text_header_value{
    color: rgba(255, 255, 255, 0.57);
}
.category_reset_btn{
    background: #9D7581;
    border-color: #9D7581;
    color: white;
}
.category_reset_btn.ant-btn-default:not(:disabled):hover{
    border-color: #b68b97;
    background: #b68b97;
    color: white;
}
.category_submit_btn{
    border-color: #3B72C4;
    background: #3B72C4;
    color: white;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    line-height: 1em;
    display: flex;
    align-items: center;
}

.Flatpickr_wrap{
    width: 100%;
min-height: 25px;
position: absolute;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 10px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 100%;
    left: 0;

}
.Period_filter_calendar.flatpickr-input{
    border-radius: 5px;
    border: none;
    text-align: center;
    color: black;

}
.Period_filter_calendar.flatpickr-input::placeholder{
    color: #bfbfbf;
    font-size: 14px;
}
.Flatpickr_wrap button{
    cursor: pointer;
    padding: 5px;
    background: inherit;
    border: none;
    color: white;
}
.Period_filter_wrapper .ant-radio-wrapper span.ant-radio+*{
    padding-right: 0;
}
.flatpickr-weekdays,
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay,
  .flatpickr-weekdaycontainer .flatpickr-weekday,
  .flatpickr-weekdays,
  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-calendar .flatpickr-months .flatpickr-month{
    background: #316397!important;
    color: white;
    border-color: #316397!important;
}
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
    box-shadow: -10px 0 0 #316397;
}



.RadioCategory_dep_popup.ant-select-dropdown .ant-select-item-option-content{
    overflow: inherit;
    white-space: normal;
}

.RadioCategory_form .list_for_category.ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: initial;
    height: 100%;

    overflow: scroll;
}
.Checkbox_line:has(.cat_name_wrap.disabled) .ant-checkbox .ant-checkbox-inner{
    border: 1px solid #a1a1a1;
}
.Checkbox_line:has(.cat_name_wrap.disabled) .cat_name_wrap{
    color: #a1a1a1;
}
.Period_filter_wrap{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Period_filter_wrapper .ant-radio-wrapper{
    width: 100%;
    color: white;
    margin: 0;
}
.Period_filter_wrap .ant-space-item{
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
}

.Period_filter_wrapper  .ant-radio{
    margin-top: 3px;
}
.Period_filter_wrapper .ant-radio.ant-wave-target{
    align-self: flex-start;


}
.RadioCategory_form .ant-form-item{
    margin-bottom: 5px;
}
.padd10{
    padding: 10px 0 0 0;
}
.form_event_btn{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

}
.ant-space-item,
.ant-space-item button{
    min-width: max-content;
    max-width: 100%;
    width: 100%;

}
