
/*.BackBlock_wrap{
    position: fixed;
}*/

.back_btn_item{
    width: max-content;
    padding: 10px;
    font-size: clamp(18px, 5vw, 24px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0 10px;
    font-weight: 600;
    background: inherit;
    line-height: 29px;
    /* identical to box height */
    cursor: pointer;

    color: #1F4DC5;
}
