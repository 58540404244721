.TableBlockModer_wrap{

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    padding: 78px 20px 55px 20px;

}
/*.TableBlock_tab{
     padding-top: 50px;
}*/
.TableBlockModer .ant-tabs-tabpane{
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
}
.TableBlockModer_tab .ant-empty,
.TableBlockModer_tab .ant-tabs-content{
    height: 100%;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.TableBlockModer_tab{

}
.TableBlockModer_tab .ant-tabs-top >.ant-tabs-nav{
    margin: 0;
}
.TableBlockModer_tab .ant-tabs-nav-list{
    grid-gap: 20px;
}
.TableBlockModer_tab .ant-tabs-tab-btn{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #00005B;
}
.TableBlockModer_tab{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
}
.TableBlockModer_tab .ant-tabs{
    width: 100%;
    max-width: 1600px;

}
.TableBlockModer_tab .ant-tabs .ant-tabs-tabpane{
    width: 100%;
    max-width: 1600px;
    align-self: flex-start;
    height: 100%;
}
