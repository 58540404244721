.LoginBlock_wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    justify-content: center;
    padding: 78px 20px 0 20px;
    user-select: none;

}

.LoginBlock_wrap {
    width: 600px;
min-width: 380px;
    height: max-content;
    max-width: 35vw;
max-height: 86vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 30px 10px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    grid-gap: 2vh;
    background: white;
    margin-top: 20px;

    overflow: hidden;


}

.LoginBlock_title_text {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    color: #1F4DC5;
    text-align: center;
    justify-content: flex-start;
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: clamp(18px, 2vw, 32px);
    line-height: 39px;

}

.LoginBlock_title_text_wrap {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;

}

.LoginBlock_wrap form {
    width: 100%;
}

.LoginBlock_wrap .ant-form-vertical .ant-form-item-label > label {
    color: #9A9A9A;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.LoginBlock_wrap .ant-form-item {
    margin-bottom: 2vh;
}

.LoginBlock_wrap .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.LoginBlock_wrap input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #333;


}

.LoginBlock_wrap .ant-input-lg {
    line-height: 55px;


}

.LoginBlock_wrap .btn_social_btn {

    height: 70px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: center;

    grid-gap: 20px;
    width: 330px;
    background: #FFFFFF;
    border: 1px solid #AFAEE7;
    border-radius: 30px;
    margin: 0 auto;
    cursor: pointer;
    text-decoration: none;

}
.LoginBlock_wrap .btn_social_btn a{
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: left;
}
.LoginBlock_wrap .btn_social_btn > span {
    text-align: left;
}

.LoginBlock_wrap .btn_social_btn:hover {
    background: #F5F5F5;
}

.LoginBlock_wrap .btn_social_btn img {
    height: 33px;
    width: auto;
}

.LoginBlock_wrap .btn_social_btn_wrap {
    width: 100%;
    height: max-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: max-content;
    grid-template-rows: max-content max-content max-content;
    grid-gap: 1vh;


}

.LoginBlock_wrap .btn_social_btn_wrap span {
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.LoginBlock_btn_social_text {
    width: 350px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 auto;
    padding: 20px;
    color: #1F4DC5;

    font-style: normal;
    font-weight: 600;

    line-height: 19px;
    text-align: center;
}

.LoginBlock_wrap .btn_submit_item {

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
/*    width: 240px;
    height: 70px;*/
    padding:2.5vh 3vw;
    border: 3px solid #1F4DC5;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1F4DC5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.LoginBlock_wrap .Email_type_input,
.LoginBlock_wrap .Password_type_input {
    box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
    max-height: 6vh;
    padding: 8px;
    overflow: hidden;
}

.LoginBlock_wrap .btn_forget_item_wrap {
    position: relative;
}

.LoginBlock_wrap .btn_forget_item {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    z-index: 100;
    cursor: pointer;
    color: #7A9DF7;
}

.LoginBlock_wrap .Item_forget {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
}

.Item_forget_wrap {
    margin-top: -15px;
}



.LoginBlock_line .ant-form-item-explain-error {
    position: absolute;

    right: 0;
}

.LoginBlock_wrap .btn_social_btn.google{
    background: #FFFFFF!important;
    border: 1px solid #AFAEE7!important;
    border-radius: 30px!important;
    box-shadow:none!important;

    cursor: pointer;
}
.LoginBlock_wrap .btn_social_btn.google:hover{
    background: #F5F5F5!important;
}
.LoginBlock_wrap .btn_social_btn.google div{
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit!important;

}
.btn_social_btn.no_work{
    cursor: inherit;
    opacity: 0.4;
}
.btn_social_btn.no_work:hover{
    background: #FFFFFF!important;
}
