
.ResolutionBlock_wrap{
    width:100%;
    height:max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content;
    grid-gap: 15px;
}
.ResolutionBlock_modal{
    width:100%;
    height:max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 15px;
}
.ResolutionBlock_modal button{
    align-self: flex-end;
}
.ResolutionBlock_modal_title_text{
    font-size: clamp(16px, 5vw, 22px);
    line-height: clamp(16px, 5vw, 22px);
    font-weight: 600;
}
.ResolutionBlock_modal_warning_text{
    font-size: clamp(12px, 5vw, 16px);
    line-height: clamp(12px, 5vw, 16px);
    color: #943535;
    font-weight: 600;
}
.ResolutionBlock_modal .ant-form-item{
    width: 100%;
}
