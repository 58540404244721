.back_btn_item{
    width: max-content;
    padding: 10px;
    font-size: clamp(18px, 5vw, 24px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0 10px;
    font-weight: 600;
background: inherit;
    line-height: 29px;
    /* identical to box height */
cursor: pointer;

    color: #1F4DC5;
}
.AddInitiatives_wrapper{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    padding: 78px 20px 20px 20px;
    overflow: auto;

}
.AddInitiatives_wrap{
min-width: 55vw;
    width: max-content;
    height: max-content;
    display: grid;
    grid-template-columns: minmax(min-content, 550px) minmax(max-content, 1fr);
   align-items: flex-start;
    justify-content: center;
    grid-gap: 15px;
    margin: 0 auto;
    overflow: auto;
    padding-top: 50px;
    padding-bottom: 100px;


}
.AddInitiatives_title_wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.AddInitiatives_title_text{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    color: #1F4DC5;

    justify-content: flex-start;
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    text-align: left;

}
.AddInitiatives_line{
    position: relative;
    margin-bottom: 10px;
}

.AddInitiatives_line .ant-form-item-explain-error{
    position: absolute;

    right: 0;
}
.AddInitiatives_leftBlock  .ant-form-vertical .ant-form-item-label,
 .ant-form-vertical .AddInitiatives_line .ant-form-item-label{
    padding: 0;
}
.street_style_input .ant-select-selector{
    max-width: 215px;

}
.AddInitiatives_leftBlock .ant-select {
    width: 100%;
    text-align: left;
}
.AddInitiatives_line_check .ant-form-item-explain .ant-form-item-explain-error{
    top:5px;
    right: 150px;
    position: absolute;
}
.AddInitiatives_wrap .Email_type_input,
.AddInitiatives_wrap .Password_type_input{
    box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
}
.AddInitiatives_wrap .tooltip_text_form_notif_wrap > ul{
    margin: 0;
    padding-right: 20px;
    padding-bottom: 10px;

}
.AddInitiatives_wrap .tooltip_text_form_notif_wrap{
    width: auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 5px;
}
.AddInitiatives_wrap .tooltip_text_form_notif_wrap{
    width: auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 5px;
}
.AddInitiatives_wrap .tooltip_text_form_notif{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
}
.AddInitiatives_wrap .tooltip_text_form_notif_wrap > ul{
    margin: 0;
    padding-right: 20px;
    padding-bottom: 10px;

}
.AddInitiatives_wrap .btn_submit_item_wrap .ant-form-item-control-input-content {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AddInitiatives_wrap .btn_submit_item {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
    width: max-content;
    height: 70px;
    border: 3px solid #1F4DC5;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1F4DC5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.item_three_block  {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 190px 190px 78px;
    grid-gap: 10px;
    position: relative;
    max-width: 100%;
}

.AddInitiatives_rightBlock{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    min-width: 500px;



}
.AddInitiatives_leftBlock{

    padding: 25px 35px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 20px;
}
.AddInitiatives_modal.ant-modal{
    min-width: max-content;
}
.AddInitiatives_modal.ant-modal .ant-modal-title{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */


    color: #1F4DC5;
}
.AddInitiatives_modal.ant-modal .ant-modal-body{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #9A9A9A;
}
.AddInitiatives_leftBlock .ant-form-item {
    margin-bottom: 15px;
}
.AddInitiatives_leftBlock .ant-upload-list.ant-upload-list-text{
    max-height: 100px;
    overflow: scroll;
    padding-right: 15px;
}
.AddInitiatives_leftBlock .ant-upload-wrapper{
    flex-direction: row;
    display: flex;
    grid-gap: 10px;
}
.AddInitiatives_leftBlock .ant-upload-wrapper .ant-upload .ant-upload .ant-btn{
    display: flex;
    height:max-content;
    align-items: center;
}
.AddInitiatives_leftBlock .ant-upload-wrapper .ant-upload .ant-upload .ant-btn .text_d_btn{
    width: max-content;
    display: flex;
    flex-direction: column;
    padding:  0 10px;
    text-align: left;

}
.AddInitiatives_leftBlock .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
    text-align: left;
}
/*.AddInitiatives_leftBlock .ant-form-item-control-input-content{
   position: relative;

}


*/
.AddInitiatives_leftBlock .iconPopoverIni{
    color: #1F4DC5;
    padding-left: 10px;


}
.AddInitiatives_leftBlock .iconPopoverIni svg{
    height: 25px;
    width: 25px;
 }
.Popover_text_wrap{
    max-width: 290px;
}
.AddInitiatives_leftBlock .ant-select-selection-item{
    text-align: left;

}
.Upload_list_ini .ant-upload-list.ant-upload-list-text{
    overflow-x: hidden;
    width: 100%;
}
.Upload_list_ini .ant-upload-list.ant-upload-list-text::-webkit-scrollbar {
    width: 5px;
    overflow-x: hidden;
}
.Upload_list_ini .ant-upload-list.ant-upload-list-text::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
}

.Upload_list_ini .ant-upload-list.ant-upload-list-text::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, #B0B0B0, #5b5a5a);
}
.Upload_list_ini .ant-upload-list.ant-upload-list-text{}


