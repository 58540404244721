.IniMapPage_wrap .shadow {
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
}


.IniMapPage_wrap .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    border-radius: 5px;
}

.IniMapPage_wrap .legend {
    text-align: left;
    line-height: 18px;
    color: #555;
}
