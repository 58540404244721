
.IniMapPage_wrap  .list_for_category .ant-checkbox-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0;
    display: flex;
    align-items: baseline;
    width: 100%;
}

.IniMapPage_wrap .list_for_category .ant-checkbox {
    align-self: center;
}

.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper > span:last-child {
    padding-top: 5px;
    text-align: left;
    line-height: normal;
    color: white;
    padding-inline-end: 0;
    width: 100%;

}

.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper > span:last-child > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.IniMapPage_wrap .Main_control .ant-collapse {
    border: none;
grid-gap: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.IniMapPage_wrap .Main_control .ant-collapse .ant-collapse-content,
.IniMapPage_wrap .Main_control .ant-collapse-item {
    border: none;
}

.IniMapPage_wrap .Main_control .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    flex-direction: row-reverse;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #316397;
    border-radius: 0;

}

.IniMapPage_wrap .Main_control .ant-form{
    width: 100%;
    overflow: hidden;
}
.IniMapPage_wrap .Main_control .ant-checkbox-group {
    overflow: scroll;
    max-height: calc(100vh - 320px);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.IniMapPage_wrap .Main_control .ant-collapse .ant-collapse-content {
    background: inherit;
    padding: 10px 0;

}

.IniMapPage_wrap .Main_control .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
}

.IniMapPage_wrap .Main_control .ant-collapse-header {
    font-size: 16px;
    text-align: left;


}

.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > span:last-child {
    color: #a6a2a2;
}

.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper.ant-checkbox-wrapper-checked > span:last-child {
    color: #00EED9;
}

.IniMapPage_wrap .list_for_category .ant-checkbox-checked .ant-checkbox-inner {
    background-color: inherit;
    border-color: #00EED9;
}
.IniMapPage_wrap .list_for_category .ant-checkbox-checked:after,
.IniMapPage_wrap .list_for_category .ant-checkbox .ant-checkbox-inner:after {
    border-color: #00EED9;
}

.IniMapPage_wrap .list_for_category .ant-checkbox .ant-checkbox-inner {
    background: inherit;
}

.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: inherit;
    border-color: #00EED9;
}
.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after,
.IniMapPage_wrap .list_for_category .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-ixblex).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #00EED9;
    background-color:inherit;
}

.IniMapPage_wrap .category_text_header{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    color: white;
    flex-direction: column;
    padding: 10px;
}
.IniMapPage_wrap .category_text_header_name{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(16px, 5vw, 22px);
    font-weight: bold;
}
.IniMapPage_wrap .category_text_header_value{
    color: rgba(255, 255, 255, 0.57);
}
.IniMapPage_wrap .category_reset_btn{
    background: #9D7581;
    border-color: #9D7581;
    color: white;
}
.IniMapPage_wrap .category_reset_btn.ant-btn-default:not(:disabled):hover{
    border-color: #b68b97;
    background: #b68b97;
    color: white;
}
.IniMapPage_wrap .category_submit_btn{
    border-color: #3B72C4;
    background: #3B72C4;
    color: white;
}
.IniMapPage_wrap .category_submit_btn.ant-btn-primary:not(:disabled):hover{
    border-color: #4a85da;
    background: #4a85da;
    color: white;
}
.IniMapPage_wrap .list_for_category_check{
    width: 100%;
    display: flex;
}
.IniMapPage_wrap .ant-collapse>.ant-collapse-item >.ant-collapse-header {
    line-height: 1em;
 display: flex;
    align-items: center;
}
.IniMapPage_wrap .list_for_category_item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 20px;
    grid-gap: 10px;
}
