/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    -webkit-appearance: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}
body {
    min-height: 100vh;


}

html {
    height: -webkit-fill-available;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

::-webkit-scrollbar { width: 0; }

.noCopy {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
/*----------------------*/
.color_btn_fault.ant-btn-default:hover,
.color_btn_revision.ant-btn-default:hover,
.color_btn_success.ant-btn-default:hover{
    color: white;
}
.color_btn_success.ant-btn-default{
background-color: #1AD079;
    color: white;
}

.color_btn_revision.ant-btn-default{
    background-color: #d07b1a;
    color: white;
}
.color_btn_fault.ant-btn-default{
    background-color: #d01a35;
    color: white;
}
.color_btn_send.ant-btn-default{
    background-color: #1a7ed0;
    color: white;
}
