.sidebar_wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    overflow: hidden;
    z-index: 100;
    width: min-content;
    padding: 0 5px 10px 5px;
}

.sidebar_wrapper > div {
    background: white;
}

.sidebar {
    width: max-content;
    height: 100%;
    transition: all linear 0.35s;
    min-width: 250px;
    grid-gap: 5px;
    display: grid;
}

.sidebar_wrap {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%, rgba(255, 255, 255, 0.5) 100%);
    box-shadow: 0 4px 30px 10px rgb(0 0 0 / 10%);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
}

.sidebar_wrap > div:first-child {
    min-height: calc(100% - 80px);
}

.sidebar > div:first-child {
    border-radius: 5px;
}

.sidebar.sbclosed {
    width: 5px;
    transition: all linear 0.75s;
    background-color: inherit;
    min-width: auto;

}

.sidebar_arrow_wrap {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    grid-gap: 5px;
}

.sidebar_arrow_wrap .ant-btn-default {
    background-color: #146eb0;
    color: white;
}

.sbcontent {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    overflow: auto;
    max-height: 100vh;
    grid-gap: 5px;
}

.card_item_wrapper {
    display: flex;
    border-bottom: 1px solid #ededed;
}

.card_item_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 35px;
    grid-template-rows: minmax(auto, 35px) repeat(auto-fill, minmax(max-content, 25px));
    background: white;
    font-size: clamp(12px, 5vw, 12px);
    cursor: pointer;
    max-width: 330px;
    -moz-user-select: none;
    user-select: none;
    padding: 5px 10px;
}

.card_item_wrap.active,
.card_item_wrap:hover {
    background: #e2f0fb;
}

.card_item {
    width: 100%;
    height: max-content;
    display: grid;
}


.Like_wrap {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
}

.Like_item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
}

.sbcontent_wrap {
    display: grid;
    grid-template-columns: 100%;
    background: #ffffff;
    grid-gap: 5px;
    border-radius: 5px;
    max-height: 100vh;
    height: max-content;
    overflow: auto;
    width: 100%;
    padding: 0 10px 10px 10px;
    box-shadow: 0 4px 30px 10px rgba(0, 0, 0, 0.1);
    grid-template-rows: minmax(100px, 150px) max-content;
    max-width: 250px;
    min-width: 250px;
}

.sbcontent_item {
    width: 100%;
    text-align: left;
    display: flex;
    grid-gap: 0 5px;
    justify-content: space-between;
}

.btn_line {
    align-items: center;
    justify-content: space-between;
}

.btn_line > span {
    text-align: left;
    color: #262626;
    font-size: clamp(12px, 3vw, 15px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(16px, 3vw, 18px);
    word-break: break-word;
}

.btn_line button {
    min-width: 35px;
    height: 35px;
}
.short_name_2line {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
}
.LNavSub_image_wrap {
    grid-gap: 5px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.sbcontent_item_gallery,
.sbcontent_item_gallery span {
    grid-gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: calc(100% + 20px);
    margin-left: -10px;
}

.sbcontent_item_gallery {
    background-color: #005797;
}

.sbcontent_items {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: clamp(10px, 5vw, 12px);
    background: #fff;
    border: 1px solid #f4f4f4;
    border-radius: 15px;
    box-shadow: 0 4px 10px 3px rgb(0 0 0 / 3%);
    padding: 5px 10px;
}

.sbcontent_title {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: #5b6dba;
    font-size: clamp(10px, 5vw, 12px);
    font-weight: 400;
}

.mark_active {
    background-color: green;
}

.mark_dis {
    background-color: gray;
}

.mark_active.ant-btn-primary:not(:disabled):hover {
    background-color: #3eab3e;
}

.mark_dis.ant-btn-primary:not(:disabled):hover {
    background-color: gray;
    cursor: inherit;
}

.btn_line svg {
    height: 25px;
    width: 24px;
}

.btn_line.name_block {
    min-height: 30px;
}

.transfer_text {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.Popup_items.transfer_text > span {
    text-align: left;
    width: 100%;
}

.sbcontent_items.docs svg {
    fill: #5b6dba;
}

.sbcontent_items.docs .scroll_x {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.sbcontent_items.docs a > svg {
    width: 40px;
    height: auto;
}

.card_item_modal .image-gallery-index {
    right: 20px;
}

.card_item_modal .ant-modal-content {
    background: rgba(24, 50, 84, 0.52);
}

.card_item_modal .anticon-close svg path {
    fill: white;
}

.sidebar_filter_wrapper {
    width: 100%;
    height: max-content;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    padding: 10px;
    background: #005797;
    color: white;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 4px 30px 10px rgba(0, 0, 0, 0.1);
}

.sidebar_filter_wrap {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.sidebar_filter_search {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/ span 2;
    grid-row: 1/ span 1;
}

.sidebar_filter_search form {
    width: 100%;
}

.LNav_filters.search_block {
    height: max-content;
}

.sidebar_filter_wrapper .ant-form-item {
    margin-bottom: 0;

}

.FeedbackItem_wrap .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0;
}

.iconFilterLayer {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconFilterLayer .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
    margin: 5px 0 5px 5px;
}

.iconFilterLayer .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #146EB0;
    border-color: #146EB0;
}

.iconFilterLayer .ant-select-status-warning:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: #005797;
    height: 40px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.iconFilterLayer .ant-select-arrow svg {
    width: 25px;
    height: 25px;
}

.iconFilterLayer_popup.ant-select-dropdown {
    background-color: #005797;
    color: white;
}

.iconFilterLayer_popup.ant-cascader-dropdown {
    padding: 5px 10px;
    box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.15);
}

.iconFilterLayer_popup.ant-cascader-dropdown .ant-cascader-menu-item:hover {
    background: #146EB0
}

.iconFilterLayer .ant-select-selection-placeholder {
    margin-right: 10px;
}

.iconFilterLayer .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    margin-right: 15px;
    color: white;
}

.iconFilterLayer .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 5px 5px 10px;
    display: flex;
    align-items: center;
    font-size: 1.3em;
}

.iconFilterLayer .ant-select-clear {
    user-select: none;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 9px;
    top: 9px;
    background-color: #146EB0;
}

.iconFilterLayer .ant-select-clear svg {
    color: #ffffff;
}

.iconFilterLayer_popup.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
    background-color: #146EB0;
    font-weight: 400
}

.iconFilterLayer_popup.ant-cascader-dropdown .ant-cascader-menu {
    height: max-content;
    min-height: 10px;
}


.card_item.first {
    width: 100%;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-weight: 400;
    color: #5b6dba;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: flex;
    display: -webkit-box;
    font-size: clamp(12px, 5vw, 14px);
    grid-column: 1/span 1;
    grid-row: 1/span 1;
    line-height: clamp(14px, 5vw, 16px);
    max-width: -webkit-max-content;
    max-width: max-content;
  /*  text-shadow: 1px 1px 1px #00000054;*/

}

.card_item.second {
    grid-row: 2/ span 1;
    grid-column: 1/ span 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: #B1B1B1;
}

.card_item.third {
    grid-row: 1/ span 1;
    grid-column: 2/ span 1;
}

.card_item.third svg {
    width: 35px;
    height: 35px;
}

.card_item.forth {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-row: 3/ span 1;
    grid-column: 1/ span 2;
    color: #B1B1B1;
    grid-gap: 5px;
}

.card_item.address {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row: 4/ span 1;
    grid-column: 1/ span 2;
    color: #B1B1B1;
    grid-gap: 5px;
}

.card_item.address span:last-child {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card_item.address svg {
    width: 100%;
    height: 100%;
    min-width: 14px;
}

.card_item.forth svg {
    display: flex;
    align-items: center;
}

.card_item.forth svg path {
    fill: #136cad99;
}

.card_item.fifth {
    grid-row: 4/ span 1;
    grid-column: 1/ span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
}

.card_item.fifth div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #B1B1B1;
    grid-gap: 5px;
}

.card_item.fifth div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.card_items_likes {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
}

.card_items_pos {
    color: black;
}

.card_items_pos.like  > svg path {
    fill: #2FC03E;
}

.card_items_likes svg {
    display: flex;
    align-items: center;
}

.card_items_likes > div span {
    grid-gap: 5px;
    color: #005790;
    font-weight: 500;
}

.card_items_neg > svg {
    transform: rotate(180deg);
}

.card_items_neg > svg path {
    fill: #FF0000;
}
.sbcontent_item{

}
.style_status_dot > span,
.sbcontent_item:not(.name_block) > span:first-child {
    width: max-content;
    color: #BDBDBD;
    min-width: max-content;
}



.sbcontent_item:not( .btn_line) svg {
    width: 15px;
    height: 15px;
}

.sbcontent_item:not( .btn_line) svg path {
    fill: #5B6DBA
}

.sbcontent_item.status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.sbcontent_item.status > span:last-child{
    margin-right: -30px;
}




.card_item.category {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    color: #595959;
    text-align: left;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 500;
}

.card_item.category svg {
    width: 30px;
    height: auto;
    padding-right: 5px;
}

.card_item.status {
    display: flex;
    grid-gap: 5px;
    color: #BDBDBD;
    width: 100%;
flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: max-content;
}



.card_item.status > div > span:first-child {
    width: 30px;
}

.sbcontent_tabs_like_wrap {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    grid-gap: 10px;
}

.sbcontent_tabs_like {
    display: flex;
    align-items: center;
}

.sbcontent_tabs_like .card_items_likes .card_items_pos ,
.sbcontent_tabs_like .card_items_likes .card_items_neg {
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-gap: 5px;
    padding: 3px 5px;
    border: 1px solid rgba(255, 0, 0, 0);
    min-width: 60px;
    width: max-content;
    border-radius: 5px;
    box-shadow: 0 1px 3px 2px rgb(0 0 0 / 21%);
}
.card_items_pos_text{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    grid-gap: 5px;
    padding: 3px 8px;
    border: 1px solid rgba(255, 0, 0, 0);
   width: min-content;
 min-width:50px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 2px rgb(0 0 0 / 21%);
}
.sbcontent_tabs_like .card_items_likes > div:hover {
    border-color: #00EED9;
}

.sbcontent_tabs_items {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -10px;
    color: #BDBDBD;
    cursor: pointer;
    padding-left: 10px;
}

.sbcontent_tabs_item.active {
    color: #005790;
}

.sbcontent_item.descriptio_line {
    grid-template-columns: 1fr
}

.Tooltip_wrap {
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 5px;
}

.Tooltip_item {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;
}

.Tooltip_item > span:first-child {
    font-weight: bold;
}

.Tooltip_wrapper .ant-tooltip-inner {
    width: max-content;
}

.sbcontent_item.docs_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 5px;
}

.sbcontent_item.docs_line img {
    cursor: pointer;
}

.map_element_items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 5px;
}
.category_wrap_line{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 5px;
}
.category_wrap_line > span{
    width: max-content;
}
.sbcontent_value{
    text-align: right;
}
.sbcontent_value.address{
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;

}
.card_items_pos_text{
    display: grid;
    width:max-content;
    cursor: pointer;
    grid-gap: 5px;
    padding: 3px 5px;
    border: 1px solid rgba(255, 0, 0, 0);

}
