.map_coords {
    width: 100%;
    height: max-content;
    display: flex;
    grid-gap: 10px;
    padding: 10px 20px;
    bottom: 0;
    position: absolute;
    z-index: 999;
    background: white;
}
.map_coords > span{
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.MapBlock_wrap{
    width:100%;
    height: 100%;
}
.ymaps-2-1-79-map.ymaps, .ymaps-2-1-79-map.ymaps:after, .ymaps-2-1-79-map.ymaps:before{
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}
.MapBlock_wrap .ymaps-2-1-79-map{
    width: 100%!important;
    height: 100%!important;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-grow: 1;
}
