.ForgotBlock_wrapper{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.ForgotBlock_wrap{
    width: max-content;
    min-width: 600px;
    height: max-content;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 50px 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    grid-gap: 40px;
    background: white;
    position: relative;
}
.ForgotBlock_title_text{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    color: #1F4DC5;
    text-align: center;
    justify-content: flex-start;
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
}
.ForgotBlock_wrap form{
    width: 100%;
}
.ForgotBlock_wrap .ant-form-vertical .ant-form-item-label >label{
    color: #9A9A9A;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
.ForgotBlock_wrap .ant-form-item{
    margin-bottom: 30px;
}
.ForgotBlock_wrap .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    content: none;
}

.ForgotBlock_wrap input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #333;
}
.ForgotBlock_wrap .ant-input-lg{
    line-height: 55px;
}
.ForgotBlock_wrap .btn_submit_item{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
    width: 240px;
    height: 70px;
    border: 3px solid #1F4DC5;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1F4DC5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.ForgotBlock_explanation{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #9A9A9A;
    padding-bottom: 26px;
}
.ForgotBlock_title_text_wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    grid-gap: 15px;
}
.ForgotBlock_title_arrow{
    cursor: pointer;
}
.Auth_btn_link{
    padding: 10px 20px;
    border-radius: 15px;
    background: rgba(0, 238, 4, 0.32);
    position: absolute;
    top: 50%;
    font-size: 1.5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.Auth_btn_link:active,
.Auth_btn_link:hover{
    background: rgba(0, 238, 4, 0.52);
}
