.RegBlock_wrapper {
    width: 100%;
    height: 100%;
    min-height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 78px 20px 150px 20px;
    overflow: scroll;

}

.RegBlock_wrap {
    width: 600px;
    min-width: 380px;
    height: max-content;
    max-width: 42vw;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 30px 10px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    grid-gap: 2vh;
    background: white;

    margin-top: 20px;


}

.RegBlock_title_text {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    color: #1F4DC5;
    text-align: center;
    justify-content: flex-start;
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;

}

.RegBlock_wrap form {
    width: 100%;
    height: 100%;
/*    overflow: auto;
    max-height: calc(100vh - 190px);*/

}

.RegBlock_wrap .ant-form-vertical .ant-form-item-label > label {
    color: #9A9A9A;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.RegBlock_wrap .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.RegBlock_wrap input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #333;


}

.RegBlock_wrap .ant-input-lg {
    line-height: 45px;


}

.RegBlock_wrap .btn_social_btn {

    height: 70px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: center;

    grid-gap: 20px;
    width: 330px;
    background: #FFFFFF;
    border: 1px solid #AFAEE7;
    border-radius: 30px;
    margin: 0 auto;
    cursor: pointer;
}

.RegBlock_wrap .btn_social_btn > span {
    text-align: left;
}

.RegBlock_wrap .btn_social_btn:hover {
    background: #F5F5F5;
}

.RegBlock_wrap .btn_social_btn img {
    height: 33px;
    width: auto;
}

.RegBlock_wrap .btn_social_btn_wrap {
    width: 100%;
    height: max-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: max-content;
    grid-template-rows: max-content max-content max-content;
    grid-gap: 20px;


}

.RegBlock_wrap .btn_social_btn_wrap span {
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.RegBlock_btn_social_text {
    width: 350px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 auto;
    padding: 20px;
    color: #1F4DC5;

    font-style: normal;
    font-weight: 600;

    line-height: 19px;
    text-align: center;
}

.RegBlock_wrap .btn_submit_item {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
    width: max-content;
    height: 70px;
    border: 3px solid #1F4DC5;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1F4DC5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.RegBlock_wrap .Email_type_input,
.RegBlock_wrap .Password_type_input {
    box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
}

.RegBlock_wrap .btn_forget_item_wrap .ant-row {
    position: relative;
}

.RegBlock_wrap .Item_assent {


    color: #9A9A9A;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    text-align: left;
    width: 100%;
    margin-left: -30px;
}

.RegBlock_wrap .Item_forget {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
}

.RegBlock_wrap .ant-form-item {

}

.RegBlock_wrap .ant-form-item.Item_forget_wrap {
    margin: 10px 0 0 10px;
}

.RegBlock_wrap .check_form_type {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #1F4DC5;
}

.RegBlock_wrap .ant-radio-wrapper {
    flex-direction: row-reverse;
}

.RegBlock_wrap .ant-form-vertical .ant-form-item-label {
    padding: 0;
}

.RegBlock_radio .ant-radio-wrapper {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #9A9A9A;
    padding-bottom: 20px;
    margin-inline-end: 25px

}

.RegBlock_line {
    position: relative;
}

.RegBlock_line .ant-form-item-explain-error {
    position: absolute;
    top: -25px;
    right: 0;
}

.RegBlock_line_check .ant-form-item-explain .ant-form-item-explain-error {
    top: 5px;
    right: 150px;
    position: absolute;
}
.Checkbox_item.RegBlock_line_check .ant-form-item-explain .ant-form-item-explain-error{
    right: 0;
}
.RegBlock_title_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.RegBlock_wrap .tooltip_text_form_notif_wrap {
    width: auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 5px;
}

.RegBlock_wrap .tooltip_text_form_notif {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
}

.RegBlock_wrap .tooltip_text_form_notif_wrap > ul {
    margin: 0;
    padding-right: 20px;
    padding-bottom: 10px;

}

.RegBlock_wrap .btn_submit_item_wrap .ant-form-item-control-input-content {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.personal_data_text {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    font-size: 14px;

}

.personal_data_text > span:first-child {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}

