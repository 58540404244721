.ProfilePage_wrapper {
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
    display: flex;
    overflow: auto;
    padding: 50px 0;
    margin: auto;
}

.ProfilePage_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
    max-width: 1110px;
    border-radius: 15px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    min-width: 30vw;

    max-height: 75vh;
overflow: auto;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .4);
background: white;

}

.profile_info {
    width: 100%;
    height: 100%;
    grid-column: 1/ span 1;
    grid-row: 1/ span 2;
    display: grid;
    grid-template-rows: minmax(300px, max-content) 1fr;
    grid-template-columns: 100%;
    grid-gap: 15px;
    padding: 0 20px;
}

.profile_ads {
    width: 100%;
    height: 100%;
    grid-column: 2/ span 1;
    grid-row: 1/ span 1;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;

    border-radius: 8px;

    padding: 5px;
}

.profile_feedback {
    width: 100%;
    height: 100%;
    grid-column: 2/ span 1;
    grid-row: 2/ span 1;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;

    border-radius: 8px;
    padding: 5px 10px;
}

.profile_feedback_items {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 5px;


}



.profile_info_img {
    width: 100%;
    height: 100%;
    min-height: 100px;
    max-height: min-content;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .7);

}

.profile_info_item {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: minmax(175px, max-content) 1fr;
    grid-template-rows: max-content;
    grid-gap: 10px;
    justify-content: space-between;

    align-items: flex-start;


}

.profile_info_item_defText {
    text-align: left;
}

.profile_item_text{
    text-align: right;
}
.profile_info_item_v {
    text-align: right;
}

.profile_info_item_defText {
    font-size: 1.5em;
    font-weight: bold;
}



.profile_info .social_network {
    width: max-content;
    height: auto;
 display: flex;
    font-size: 32px;
    color: #000000;
    grid-gap: 10px;

}

.profile_info_items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 15px;
    font-size: clamp(12px, 5vw, 16px);
    position: relative;
}
.profile_info_item.oked > span:first-child{
    text-align: left;
}
.profile_info_item.oked > span:last-child{
    text-align: right;
}
.profile_item_title {
    font-size: clamp(14px, 5vw, 18px);
    font-weight: 600;
    text-align: left;
}

.profile_ads_item {
    width: 100%;
    height: 70px;
    background: #d0d0d052;
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-template-rows: 100%;
    max-height: 70px;

    padding: 2px 10px 2px 5px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
    cursor: pointer;


}
.profile_ads_click{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100% ;
    grid-template-rows: 100%;
    grid-gap: 10px;
    padding-right: 5px;
}
.profile_ads_item:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .7);
    background: rgba(255, 255, 255, 0);
}

.profile_feedback_items .profile_ads_item {
    margin-bottom: 5px;
}

.profile_ads_item_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    padding: 10px;
    margin-top: -20px;


}

.profile_ads_info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.profile_ads_info_text {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    grid-template-rows: max-content;
    width: 100%;
    height: 100%;
text-align: left;
    padding: 5px;
}
.profile_ads_info_text .p_id{
    grid-column: 1/ span 2;
    grid-row: 1/ span 1;
}
.profile_ads_info_text .p_cat{
    grid-column: 1/ span 2;
    grid-row: 2/ span 1;
}
.profile_ads_info_text .p_date{
    grid-column: 1/ span 1;
    grid-row: 3/ span 1;
}
.profile_ads_info_text .p_voteAmount{
    grid-column: 2/ span 1;
    grid-row: 2/ span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #0d8f19;;
}
.profile_ads_img {
    height: 100%;
    width: auto;
    overflow: hidden;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../assets/defUser.webp);
    border-radius: 8px 0 0 8px;
}



.profile_ads_info_text > span {
    display: flex;
    align-items: center;
    height: 100%;
}
.profile_ads_info_text > span:first-child{
    font-size: clamp(12px, 5vw, 16px);
    line-height:  clamp(12px, 5vw, 16px);;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;


    max-width: -webkit-max-content;
    height: max-content;
   /* text-shadow: 1px 1px 1px #00000054;*/
    width: 100%;
}
.profile_ads_info_text > span:nth-child(2){
    font-size: clamp(10px, 5vw, 12px);
}
.profile_ads_info_text > span:nth-child(3){
    font-size: clamp(8px, 5vw, 11px);
}
.profile_ads_info_btn {
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    padding: 0 ;
}

.profile_ads_info_btn > span {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;

    border-radius: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
}

.profile_ads_info_btn > span:hover {
    background: white;
}

.ant-tabs-content-holder {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 15px 0 0 0;
    overflow-x: hidden;
}

.profile_ads_items {
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.ant-tabs.ant-tabs-card {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr;
    gap: 10px;

}

.profile_ads_title_block {
    font-size: clamp(16px, 5vw, 22px);
    text-align: left;
    padding-left: 10px;
    font-weight: 600;
    color: rgba(92, 92, 92, 1);
}

.profile_ads_items .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
}

.profile_ads_items .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.profile_ads_items .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-radius: 8px;
    background: #1677ff;
    color: white;
}

.ant-tabs-top > .ant-tabs-nav {
    margin: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none;

}

.profile_info .social_network > a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_info_item_ {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 20px;
}
.profile_info_item_edit {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    cursor: pointer;
}
.profile_info_item_edit  svg{
    width: 25px;
    height: 25px;
}
.profile_info_item_edit:hover svg path{
    fill:#353698;
}
.Profile_control_item{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
padding-bottom: 20px;
    bottom: 0;
}
.Profile_control_info{
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 15px;
}
.Profile_control_info svg{
    width: 30px;
    height: 30px;
    padding-top: 5px;

}
.Profile_control_info > span{
    cursor: pointer;
}
.profile_address_items{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    grid-gap: 5px;
}
.profile_info_item.address{
    display: grid;
    grid-template-columns: 175px 1fr;


}
.profile_address_items{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-wrap: wrap;
}
.profile_address_items > span{
    width: max-content;
}
.stroke_round_1{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;

}

.profile_ads_modal {
    min-width: clamp(400px, 90vw, 1100px);
    max-width: max-content;
    min-height: max-content;
    display: flex;
    background: white;

    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .7);
    padding: 0;
}
.profile_ads_modal .AddAdsPage_wrap{
border-radius: 0;
}
.EditProfilePage_photo_social .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.EditProfilePage_photo_social .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select{
    min-width: 100%;
    overflow: hidden;
}
.disabledBtn{
    opacity: .2;
}
.profile_ads_modal .AddAdsPage_wrapper .AddAdsPage_wrap{
    width: 100%;
    min-width: 100%;
}
.profile_ads_modal .AdsItem_wrapper{
    padding: 0;
}
.profile_tooltip_wrapper{

    text-align: left;
    line-height: clamp(12px, 5vw, 18px);
    font-size: clamp(12px, 5vw, 16px);
}
.ant-tabs-content-holder{

    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.2)
}

.ant-tabs-top >.ant-tabs-nav::before{
    border: none;
}
.btn_submit_item_wrap .ant-btn{
    padding: 5px 10px;
}
.profile_ads_items .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
    padding: 5px 10px;
}
.profile_ads_items .ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon:not(:last-child) {
    margin-inline-end: 5px;
}

.star svg path{
    fill: gold;



}

.p_voteAmount svg{
margin-top: 2px;
    width: 20px;
    height: 20px;

}
.p_voteAmount svg path{
    fill: #0d8f19;
}
@media (max-width: 980px) {
    .ProfilePage_wrap{
        grid-template-columns: 1fr;
        max-height: 85vh;
        grid-gap: 0;
    }
    .profile_info{
        min-height: max-content;
        height: max-content;
        grid-row: 1 / span 1;
        grid-template-rows:  1fr;
        grid-template-columns: 1fr 1fr;
        display: flex;
        flex-direction: column;


    }
    .profile_ads{
        min-width: 100%;
        height: max-content;
        grid-column: 1 / span 1;
        grid-row: 4 / span 1;
        min-height: 300px;
    }
    .profile_feedback{
        min-width: 100%;
        height: max-content;
        grid-column: 1 / span 1;
        grid-row: 5 / span 1;
        min-height: 260px;
    }
    .profile_feedback,
    .profile_ads_items{
        max-height: 270px;
    }
    .profile_info_img{
        max-width: 50vw;
    }
    .Profile_control_item{
        position: absolute;
        top: -130px;
        flex-direction: column;
        justify-content: flex-end;
 right: 0;
        width: max-content;
    }
    .Profile_control_info{
        flex-direction: column;
        grid-gap: 5px;
        align-self: end;
        padding-bottom: 10px;
    }
}
