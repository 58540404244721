.FeedbackItem_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 300px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.67) 100%, rgba(255, 255, 255, 0.5) 100%);
    grid-gap: 10px;
    border-radius: 10px;

    max-width: 300px;
    height: 100%;
    overflow: auto;
    width: 100%;
    padding: 10px 10px 10px 10px;
    box-shadow: 0 4px 30px 10px rgba(0, 0, 0, 0.1);
    max-height: 550px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 25px 1fr;
}

.FeedbackItem_wrap {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;

}

.FeedbackItem_item {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 15px;
    border-radius: 15px;

    background: #ffffff;
    border: 1px solid gray;
    min-height: 60px;
    font-size: clamp(10px, 5vw, 14px);
    cursor: pointer;

}

.FeedbackItem_item.like:hover,
.FeedbackItem_item.like.active {
    box-shadow: 0px 4px 10px 5px rgba(25, 165, 38, 0.1);
    border-color: #19A526;
}

.FeedbackItem_item.dislike:hover,
.FeedbackItem_item.dislike.active {
    border-color: #D51E3C;
    box-shadow: 0px 4px 10px 5px rgba(213, 30, 60, 0.1);;
}

.FeedbackItem_title {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(16px, 5vw, 22px);
    padding: 10px;
    line-height: 25px;
    font-weight: 600;
}

.FeedbackItem_title2 {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #6C6C6C;
    font-size: clamp(10px, 5vw, 14px);
}


.FeedbackItem_wrap.ant-checkbox-group {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
}

.FeedbackItem_result_item,
.FeedbackItem_wrap.ant-checkbox-group .ant-checkbox-wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 15px;
    border-radius: 15px;

    background: #ffffff;
    border: 1px solid gray;

    font-size: clamp(10px, 5vw, 14px);
    cursor: pointer;
}

.FeedbackItem_wrap.ant-checkbox-group .ant-checkbox-wrapper {
    min-height: 60px;
}

.FeedbackItem_result_item {
    cursor: inherit;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 10px;
    width: 100%;
    height: max-content;
}

.FeedbackItem_wrap.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    display: none;
}


.FeedbackItem_wrap.like.ant-checkbox-group .ant-checkbox-wrapper:hover,
.FeedbackItem_wrap.like.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    box-shadow: 0 4px 10px 5px rgba(25, 165, 38, 0.1);
    border-color: #19A526;
    background: rgb(199 245 203);
}

.FeedbackItem_wrap.dislike.ant-checkbox-group .ant-checkbox-wrapper:hover,
.FeedbackItem_wrap.dislike.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    border-color: #D51E3C;
    box-shadow: 0 4px 10px 5px rgba(213, 30, 60, 0.1);
    background: #ffd2da;
}

.FeedbackItem_wrap.like.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span:before,
.FeedbackItem_wrap.dislike.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span:before {
    content: "✓  ";


}

.FeedbackItem_btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
}

.FeedbackItem_btn .ant-btn {
    background: none;
    border: 1px solid #005797;
    color: #005797;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    padding: 10px 0;
}

.blue_btn.ant-btn {
    background: #005797;
    color: white;
    text-decoration: none;

}

.FeedbackItem_list .ant-row {
    margin: 0 0 1.5em;
}

.FeedbackItem_list {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 100%;
}

.FeedbackItem_wrap.result::-webkit-scrollbar,
.FeedbackItem_list::-webkit-scrollbar {
    width: 7px;

}

.FeedbackItem_wrap.result::-webkit-scrollbar-track,
.FeedbackItem_list::-webkit-scrollbar-track {
    background: none;

}

.FeedbackItem_wrap.result::-webkit-scrollbar-thumb,
.FeedbackItem_list::-webkit-scrollbar-thumb {
    background: rgba(0, 87, 151, 0.5);

    border-radius: 5px;
}

.FeedbackItem_list .ant-form-item-control-input-content {
    margin-right: 5px;
}
.form_likes .ant-form-item{
    margin-bottom: 0;
}
.result_likes {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;

}

.FeedbackItem_result_title {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    grid-gap: 5px;
}

.FeedbackItem_wrap.result {
    max-height: 34vh;
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}

.FeedbackItem_result_value {
    font-size: 1.2em;
    font-weight: bold;
    color: #146EB0;
}

.Title_like_head {
    font-size: 1.5em;
}

.FeedbackItem_radio .ant-radio-wrapper span {
    font-size: 1.2em;
}
.form_likes{
    display: grid;
    grid-template-rows: max-content 1fr minmax(50px, max-content);
    grid-template-columns: 100%;
    max-height: 100%;
    overflow: hidden;
    align-self: flex-start;
    height: 100%;
}
.FeedbackItem_wrapper:has(.FeedbackItem_result_title){
max-height: max-content;
    grid-template-rows: max-content max-content 1fr max-content;
    overflow: hidden;
}
