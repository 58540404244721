
.HeaderBlock_wrap {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(max-content, 1fr) 1fr 1fr 1fr 1fr max-content max-content minmax(max-content, 150px);
    padding: 0 0 0 2vw;
    width: 100vw;
    height: 100%;
grid-gap: 2vw;
    align-items: center;

    z-index: 100;


    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    max-height: 78px;
    position: fixed;
    top: 0;

}
.HeaderBlock_wrap> div{
    overflow: hidden;
}
.HeaderBlock_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    text-align: left;
    grid-gap: 10px;
    text-decoration: none;
    cursor: pointer;


}
.HeaderBlock_item img,
.HeaderBlock_wrap img{
    width: 50px;
    height:auto;
}
.HeaderBlock_wrap svg{
/*    width: 50px;
    height: auto;*/
}


.HeaderBlock_item span {
    line-height: 16px;
    color: #353698;
}

.cifra_logo_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    color: #353698;

}

.cifra_logo_text > span:nth-child(1) {
    font-size: 0.8em;
}

.cifra_logo_text > span:nth-child(2) {
    font-size: 1em;
    font-weight: 500;
}

.cifra_logo_text > span:nth-child(3) {
    font-size: 0.8em;
}

.HeaderBlock_btn {
    margin: 0 auto;
    cursor: pointer;
    z-index: 99999;
}


.Title_block_name {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0 10px;



}

.Contact_block {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 10px;
}

.Contact_block_item {
    color: #1AD079;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;

    text-decoration: none;

    padding: 5px 10px;
    border: 1px solid ;
    border-radius: 10px;
}



.akim_panel {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang_block {
    width: max-content;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.lang_block > span:first-child {
  border-right: 1px solid;
    padding-right: 10px;

}
.lang_block > span:last-child {
    border-left: 1px solid;
    padding-left: 10px;
    margin-left: -1px;

}
.inner_text{
    cursor: pointer;
}
.lang_btn_it.active,
.inner_text,
.lang_block > span:last-child:hover,
.lang_block > span:first-child:hover {
z-index: 10;
    color: #1AD079;
}

.home_icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}

.home_icon {
    font-size: 16px;


}
.Header_logo_text,
.cifra_logo_text,
.home_icon_text {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #353698;

}

.top_pad{
    padding-bottom: 5px;
}
.inner_text_log{
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
}
.inner_text_log span{
    max-width: 100px;
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
}
.ant-dropdown-trigger{
  display: flex;
}
