.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_info_dolzhost{
    font-size: clamp(12px, 5vw, 15px);
}
.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_info_title{
    font-size: clamp(14px, 5vw, 17px);
}
.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_info_fio{
    font-size: clamp(16px, 5vw, 19px);
}
.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_item{
    font-size: clamp(12px, 5vw, 15px);
}
.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_item {
    width: calc(100% - 10px);
}

.IniMapPage_wrap .DistrictNav_info_img {
    max-height: 175px;
}
.IniMapPage_wrap .DistrictNav_wrap {
    max-width: 260px;
}

.IniMapPage_wrap .DistrictNav_wrap .DistrictNav_info_img {
    min-height: 200px;
}
.IniMapPage_wrap .DistrictNav_info_img {
    max-height: 175px;
}


.IniMapPage_wrap .DistrictNav_wrap{
    display: flex;
    width: 300px;

    flex-direction: column;
    grid-gap: 20px;
    position: relative;
    transition: .3s;
    background: inherit;
    height: 100%;
    color: white;

}
.IniMapPage_wrap .DistrictNav_btn{
    position: absolute;
    top: 10px;
    left: 15px;
}
.IniMapPage_wrap .DistrictNav_wrap.false .DistrictNav_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}
.IniMapPage_wrap .gerb_bg{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.IniMapPage_wrap .DistrictNav_info_img{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    max-height: 250px;
    background-position: center;



}
.IniMapPage_wrap .DistrictNav_item{
    width: calc(100% - 30px);
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 20px;
    grid-gap: 15px;
    cursor: pointer;
    border-radius: 0 15px 15px 0;
}
.IniMapPage_wrap .DistrictNav_item > span{
    display: flex;
    flex-wrap: nowrap;
    min-width: max-content;
}
.IniMapPage_wrap .DistrictNav_item.active,
.IniMapPage_wrap .DistrictNav_item:hover{
    background: #32578E;
}
.IniMapPage_wrap .DistrictNav_info_wrap{
    width: 100%;
    min-height: 290px;
    max-height:max-content;
    height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    grid-gap: 10px;

    transition: 3s;

}
.IniMapPage_wrap .DistrictNav_info_title{
    width: 100%;
    height: max-content;
    display: flex;
    font-size: clamp(16px, 5vw, 22px);
    padding: 0 20px;
}
.IniMapPage_wrap .DistrictNav_info_fio{
    font-weight: 600;
    font-size: clamp(16px, 5vw, 22px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    line-height: clamp(19px, 5vw, 25px);
    text-align: center;
    padding: 0 20px 10px 20px;

}
.IniMapPage_wrap .DistrictNav_info_dolzhost{
    font-weight: 400;
    font-size: clamp(12px, 5vw, 18px);
    line-height: 15px;
    /* identical to box height */
    color: rgba(255, 255, 255, 0.5);


}
.IniMapPage_wrap .DistrictNav_item {
    text-align: left;
    line-height:1.2em;
}
/**/
.IniMapPage_wrap .DistrictNav_wrap.false{

    transform: translateX(calc(-100% + 80px));
    will-change: transform;
    transition: .3s;
    width: 80px;

}
.IniMapPage_wrap .DistrictNav_wrap.false .DistrictNav_item {
    width: calc(100% - 5px);
}

.IniMapPage_wrap .DistrictNav_info_wrap.true{
    display: none;
    transition: 3s;
}

.IniMapPage_wrap .DistrictNav_item_wrap.true{
    padding-top: 30px;

}
